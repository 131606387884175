<template>
  <div>
    <div v-if="loading.notifications" class="space-y-3">
      <UiSkeleton v-for="i in 4" :key="i" class="h-14" />
    </div>

    <LazyUiErrorState
      v-else-if="loading.error && !loading.notifications"
      message="Error fetching notifications"
      @try-again="fetchNotifications"
    />

    <template v-else>
      <template v-if="notifications">
        <LazyUiEmptyState v-if="notifications.length === 0">
          No notifications available
        </LazyUiEmptyState>
        <template v-else>
          <h3>Today</h3>
          <div
            v-if="todayNotifications.length === 0"
            class="mt-5 text-center text-sm"
          >
            No notifications today
          </div>
          <NotificationBlock
            v-else
            :notifications="todayNotifications"
            @close="$emit('close')"
          />
          <template v-if="olderNotifications.length">
            <h3 class="mt-5">Older</h3>
            <NotificationBlock
              :notifications="olderNotifications"
              @close="$emit('close')"
            />
          </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { Notification } from '~/types/notification'

defineEmits(['close'])

// Reusable composables
const { notifications } = storeToRefs(useNotificationStore())
const { loading, fetchNotifications } = useNotification()

if (!notifications.value) {
  fetchNotifications()
}

const todayNotifications = computed(() => {
  const notification: Notification[] = [...notifications.value!]
  return notification.filter((noti: Notification) => isToday(noti.createdAt))
})
const olderNotifications = computed(() => {
  const notification: Notification[] = [...notifications.value!]
  return notification.filter((noti: Notification) => !isToday(noti.createdAt))
})

function isToday(date: string) {
  const today = new Date()
  if (today.toDateString() === new Date(date).toDateString()) {
    return true
  }
  return false
}
</script>
