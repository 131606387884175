<template>
  <ul
    class="mt-2 divide-y divide-grey bg-white dark:divide-grey/10 dark:bg-dark-primary"
  >
    <li
      v-for="notification in notifications"
      :key="notification._id"
      class="py-5"
    >
      <div class="flex items-start">
        <NotificationIcon :notification-type="notification.type" />
        <div class="ml-3">
          <!-- Wallet type notifications -->
          <p v-if="notification.type === 'wallet-withdraw'" class="text-sm">
            <strong> ₦{{ formatAmount(notification.metadata.amount) }} </strong>
            withdrawn from your wallet successfully.
          </p>
          <p v-if="notification.type === 'wallet-fund'" class="text-sm">
            You just topped up your wallet with
            <strong> ₦{{ formatAmount(notification.metadata.amount) }} </strong>
          </p>
          <p v-if="notification.type === 'wallet-debit'" class="text-sm">
            <strong> ₦{{ formatAmount(notification.metadata.amount) }} </strong>
            was debited from your wallet.
          </p>
          <p v-if="notification.type === 'wallet-credit'" class="text-sm">
            <strong> ₦{{ formatAmount(notification.metadata.amount) }} </strong>
            was credited into your wallet successfully.
          </p>
          <p v-if="notification.type === 'wallet-refund'" class="text-sm">
            <strong> ₦{{ formatAmount(notification.metadata.amount) }} </strong>
            was refunded into your wallet successfully.
          </p>
          <template v-if="notification.type === 'wallet-transfer'">
            <p
              v-if="
                notification.type === 'wallet-transfer' &&
                notification.metadata.debitAccountNickname === user?.nickname
              "
              class="text-sm"
            >
              You transferred
              <strong>
                ₦{{ formatAmount(notification.metadata.amount) }}
              </strong>
              to
              <strong>
                @{{ notification.metadata.creditAccountNickname }}
              </strong>
            </p>
            <p v-else class="text-sm">
              <strong>@{{ notification.metadata.debitAccountNickname }}</strong>
              transferred
              <strong>
                ₦{{ formatAmount(notification.metadata.amount) }}
              </strong>
              to you.
            </p>
          </template>

          <!-- Quickdraw type notifications -->
          <p v-if="notification.type === 'quickdraw-completed'" class="text-sm">
            "<strong> {{ notification.metadata.name }} </strong>" draw is
            completed.
          </p>

          <!-- Invite type notifications -->
          <p v-if="notification.type === 'invite-pending'" class="text-sm">
            <strong>@{{ notification.metadata.nickname }}</strong>
            has invited you to partake in "<strong class="capitalize">
              {{ notification.metadata.name }} </strong
            >" draw.
          </p>
          <p v-if="notification.type === 'invite-accept'" class="text-sm">
            <strong>@{{ notification.metadata.nickname }}</strong>
            has accepted your "<strong class="capitalize">
              {{ notification.metadata.name }} </strong
            >" invite.
          </p>
          <p v-if="notification.type === 'invite-cancel'" class="text-sm">
            <strong>@{{ notification.metadata.nickname }}</strong>
            has cancelled the "<strong class="capitalize">
              {{ notification.metadata.name }} </strong
            >" invite, if you had already accepted the invite your staked money
            will be refunded back to your wallet.
          </p>
          <p v-if="notification.type === 'invite-reject'" class="text-sm">
            <strong>@{{ notification.metadata.nickname }}</strong>
            has rejected your "<strong class="capitalize">
              {{ notification.metadata.name }} </strong
            >" invite
          </p>
          <p v-if="notification.type === 'invite-completed'" class="text-sm">
            "<strong class="capitalize">
              {{ notification.metadata.name }} </strong
            >" draw is completed.
          </p>
          <p v-if="notification.type === 'invite-remove'" class="text-sm">
            <strong class="capitalize">
              @{{ notification.metadata.nickname }}
            </strong>
            has removed you from "<strong>
              {{ notification.metadata.name }} </strong
            >" invite.
          </p>

          <!-- Event type notifications -->
          <p v-if="notification.type === 'event-completed'" class="text-sm">
            "<strong>
              {{
                notification.metadata ? notification.metadata.title : ''
              }} </strong
            >" event is completed.
          </p>

          <p class="mt-1 text-xs text-light-text dark:text-dark-light-text">
            {{ formatDate(notification.createdAt) }}
          </p>
          <UiButton
            v-if="showButton(notification)"
            variant="outline"
            size="sm"
            rounded
            class="mt-3"
            @click="navigateToPath(notification)"
          >
            {{ buttonText(notification) }}
          </UiButton>
        </div>
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
import type { Notification } from '~/types/notification'

defineProps<{
  notifications: Notification[]
}>()

const emit = defineEmits(['close'])

// Reusable composables
const { user } = storeToRefs(useUserStore())

function showButton(notification: Notification) {
  return (
    notification.type === 'quickdraw-completed' ||
    notification.type === 'invite-pending' ||
    notification.type === 'invite-completed' ||
    (notification.type === 'event-completed' && notification.metadata)
  )
}
function buttonText(notification: Notification) {
  if (notification.type === 'quickdraw-completed') {
    return 'View Activity'
  } else if (notification.type === 'invite-pending') {
    return 'View Invite'
  } else if (notification.type === 'invite-completed') {
    return 'View History'
  } else if (notification.type === 'event-completed') {
    return 'View Event'
  }
}
function navigateToPath(notification: Notification) {
  emit('close')
  if (notification.type === 'quickdraw-completed') {
    return navigateTo('/activities')
  } else if (notification.type === 'invite-pending') {
    return navigateTo('/games/quickdraw/invite')
  } else if (notification.type === 'invite-completed') {
    return navigateTo('/games/quickdraw/invite?type=history')
  } else if (notification.type === 'event-completed') {
    return navigateTo(`/games/factoes/event/${notification.metadata.eventId}`)
  }
}
</script>
