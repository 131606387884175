<template>
  <div
    class="flex size-10 items-center justify-center rounded-full bg-primary/20 text-primary shadow-custom shrink-0"
  >
    <component :is="icon(notificationType)" class="size-5" />
  </div>
</template>

<script setup lang="ts">
import TrendUpIcon from '@/assets/icons/trend_up.svg?component'
import TrendDownIcon from '@/assets/icons/trend_down.svg?component'
import ClockIcon from '@/assets/icons/clock.svg?component'
import ExclamationIcon from '@/assets/icons/exclamation.svg?component'
import CheckIcon from '@/assets/icons/circle-check.svg?component'
import MinusIcon from '@/assets/icons/minus-circle.svg?component'
import TransferIcon from '@/assets/icons/transfer.svg?component'

defineProps<{
  notificationType: string
}>()

function icon(type: string) {
  if (
    type === 'wallet-credit' ||
    type === 'wallet-refund' ||
    type === 'wallet-fund'
  ) {
    return markRaw(TrendUpIcon)
  } else if (type === 'invite-pending') {
    return ClockIcon
  } else if (type === 'invite-reject' || type === 'invite-cancel') {
    return markRaw(ExclamationIcon)
  } else if (type === 'wallet-debit' || type === 'wallet-withdraw') {
    return markRaw(TrendDownIcon)
  } else if (
    type === 'quickdraw-completed' ||
    type === 'invite-accept' ||
    type === 'invite-completed' ||
    type === 'event-completed'
  ) {
    return markRaw(CheckIcon)
  } else if (type === 'invite-remove') {
    return markRaw(MinusIcon)
  } else if (type === 'wallet-transfer') {
    return markRaw(TransferIcon)
  }
}
</script>
